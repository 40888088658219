



















































































































































































































































































































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
import moment from "moment";
// import Paystack from "vue-paystack";

import CartItem from "@/components/checkout/CartItem.vue";
import { Business, Client, Order, Role, User } from "@/types";
import { validatePhone } from "@/util/payment";
import OrderStoreModule from "@/store/modules/order";
import paystackStoreModule from "@/store/modules/paystack";
import { PHONE_REGEX_KE } from "@/util/constants";
import voucherStoreModule from "@/store/modules/voucher";

const { mapActions: orderActions } = createNamespacedHelpers("ORDER_CHECKOUT");

const { mapActions: paystackActions } = createNamespacedHelpers("PAYSTACK_");

const { mapActions: voucherActions } = createNamespacedHelpers("CHECK_VOUCHER");

const payment_items = [
  { title: "Apply Voucher", value: "voucher" },
  { title: "Pay By Cash", value: "cash" },
  { title: "Pay By Mpesa", value: "mpesa" },
];

const PAYSTACK_PUBLIC_KEY = process.env.VUE_APP_PAYSTACK_PUBLIC_KEY;

export default Vue.extend({
  //<any, any, any, any>
  name: "AppointmentCheckoutPage",
  components: { CartItem },
  props: {
    appointmentId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isLoading: false,
    loadingMessage: "Processing Payment...",
    voucherDialog: false,
    cashDialog: false,
    cardDialog: false,
    qrDialog: false,
    mpesaDialog: false,
    gift_code: "",
    paymentItems: payment_items,
    qr: {
      step: 1,
      timer: 300,
      creatingOrder: false,
      fetchingOrder: false,
      interval: null,
    },
    cash: {
      cash_amount: 0,
      cash_balance: 0,
      total_amount: 0,
    },
    mpesa: {
      total_amount: 0,
      phone: "",
    },
    qrcode: "",
    selectedQRPaymentMethod: undefined,
    mpesaPhoneRules: [
      (v: string) => !!v || "Phone Number is required",
      (v: string) => PHONE_REGEX_KE.test(v) || "Invalid phone number",
    ],
    pollInterval: undefined as any,
    pollTimeout: undefined as any,
    paystackDialog: false,
    paystack: {
      key: PAYSTACK_PUBLIC_KEY,
      amount: 0,
      orderId: "",
    },
    authUrl: "",
    voucherAmount: 0,
    cardDeposit: false,
    payment: {
      method: "",
      currency: "KES",
      amount: 0,
      source: "",
    },
    order: {} as Order,
    paymentDone: false,
    appliedVouchers: [] as { clientVoucherId: string; appliedAmount: number }[],
  }),
  computed: {
    appliedDiscounts() {
      return this.$store.getters["cart/discounts"];
    },
    cartQty: function (): number {
      return this.order?.services?.reduce(
        (acc: number, service: any) => acc + service.quantity,
        0
      );
    },
    cartTotal: function (): number {
      return this.order?.services?.reduce(
        (acc: number, service: any) =>
          acc + service.quantity * service.unitPrice,
        0
      );
    },
    services() {
      return this.order.services?.map((service) => ({
        ...service,
        id: service._id,
        cost: service.quantity * service.unitPrice,
        appointmentDate: moment(service.appointmentDate).format("YYYY-MM-DD"),
        appointmentTime: moment(service.appointmentTime).format("HH:mm"),
      }));
    },
    payableAmount: function (): number {
      return this.cartTotal - this.order.payment.amount;
    },
    grandTotal: function (): number {
      return this.cartTotal - this.order.payment.amount;
    },
    role(): Role {
      return this.$store.getters.role;
    },
    vendor(): Business {
      return this.role.business as Business;
    },
    employee(): User {
      return this.role.user as User;
    },
    user(): User {
      return this.$store.getters.user;
    },
    depositAmount(): number {
      return this.order.depositAmount ?? 0;
    },
    discountAmount(): number {
      return this.order.discountAmount ?? 0;
    },
    cartTotalWithDeposit(): number {
      return this.$store.getters["cart/totalWithDeposit"];
    },
  },
  watch: {
    cash: {
      handler() {
        if (!this.order._id) return;
        const { cash_amount, cash_balance } = this.cash;
        const total = this.grandTotal;
        this.cash.cash_balance = total > cash_amount ? 0 : cash_amount - total;
        this.cash.total_amount = cash_amount - cash_balance;
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.getOrder();
  },
  methods: {
    ...orderActions([
      "retryOrderPayment",
      "fetchOrder",
      "retryAppointmentPayment",
    ]),
    ...paystackActions(["initTransaction"]),
    ...voucherActions(["validateVoucher"]),
    closedPaymentModal(response: any) {
      console.log("payment modal is closed");
      console.log(response);
    },
    getOrder() {
      this.fetchOrder(`?appointmentId=${this.appointmentId}`).then(
        (order: any) => {
          this.order = order;
        }
      );
    },
    openDialog(type: string) {
      if (type == "voucher") {
        this.voucherDialog = true;
      }

      if (type == "cash") {
        this.cashDialog = true;
        this.cash = {
          cash_amount: 0,
          cash_balance: 0,
          total_amount: 0,
        };
      }

      if (type == "card") {
        this.cardDialog = true;
      }

      if (type == "mpesa") {
        this.mpesa.total_amount = this.grandTotal;
        this.mpesaDialog = true;
      }
      if (type == "qr") {
        this.qrDialog = true;
      }
    },
    formatPhone(phone: string) {
      const codedPlus =
        /^(?:\+254)?(7|1(?:(?:[12][0-9])|(?:0[0-8])|(9[0-2]))[0-9]{6})$/;
      const zero = /^(?:0)?(7|1(?:(?:[12][0-9])|(?:0[0-8])|(9[0-2]))[0-9]{6})$/;
      const coded =
        /^(?:254)?(7|1(?:(?:[12][0-9])|(?:0[0-8])|(9[0-2]))[0-9]{6})$/;

      if (phone.match(codedPlus)) {
        const newPhoneNumber = phone.substring(1);
        return newPhoneNumber;
      }

      if (phone.match(coded)) {
        return phone;
      }

      if (phone.match(zero)) {
        const newPhoneNumber = phone.substring(1);
        const newPhone = `254${newPhoneNumber}`;
        return newPhone;
      }
      return phone;
    },
    async doMpesaPayment() {
      const valid = (
        this.$refs.mpesaForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;

      const { phone } = this.mpesa;

      if (!validatePhone(phone)) {
        this.$swal.fire({
          icon: "error",
          title: "Payment Not Initialized",
          text: "Please provide a valid phone Number!",
        });
        return;
      }

      this.payment = {
        method: "m-pesa", //card|m-pesa|cash|paylater
        source: phone,
        amount: this.grandTotal,
        currency: "KES",
      };
      this.retryPayment();
    },
    async retryPayment() {
      if (this.payment.method) {
        this.isLoading = true;
        this.paymentDone = false;
        const payload: any = {
          id: this.appointmentId,
          data: {
            payment: this.payment,
          },
        };

        if (this.appliedVouchers.length) {
          payload.data.appliedVouchers = this.appliedVouchers;
        }
        this.retryAppointmentPayment(payload).then((res) => {
          if (res) {
            this.getAppointment();

            this.processOrderPayment(this.order._id);
            if (res.authUrl) {
              this.authUrl = res.data.authUrl;
              this.paystackDialog = true;
            }
          }
        });
      }
    },
    getAppointment() {
      const interval = setInterval(() => {
        this.fetchOrder(`?orderId=${this.order._id}`).then((appt: any) => {
          if (appt?.paymentStatus === "completed") {
            clearInterval(interval);
            //this.paymentDialog = false;
            this.paystackDialog = false;

            this.$swal.fire({
              icon: "success",
              title: "Payment Successful!",
              text: "Payment has been successfully processed",
            });

            this.$emit("data-saved", true);
          }
        });
      }, 3000);
    },
    async applyVoucher() {
      if (this.gift_code != "") {
        const payload = {
          voucherCode: this.gift_code,
          services: this.order.services.map((service) => ({
            id: service._id,
            cost: this.grandTotal,
          })),
        };

        const data = await this.validateVoucher(payload);

        if (data) {
          const { clientVoucher, applyAmount } = data;

          if ((this.order.client as Client)._id !== clientVoucher.client._id) {
            this.$swal.fire({
              icon: "error",
              title: "Voucher Error",
              text: "Invalid Client",
            });
            return;
          }
          this.voucherAmount = applyAmount;

          this.payment = {
            method: "voucher", //card|m-pesa|cash|paylater
            source: this.gift_code,
            amount: this.grandTotal,
            currency: "KES",
          };

          this.appliedVouchers = [
            {
              clientVoucherId: clientVoucher._id,
              appliedAmount: applyAmount,
            },
          ];
          this.voucherDialog = false;
          this.retryPayment();
        }
      }
    },
    async doCashPayment() {
      this.payment = {
        method: "cash", //card|m-pesa|cash|paylater
        source: (this.order.client as Client).phone,
        amount: this.grandTotal,
        currency: "KES",
      };
      this.loadingMessage = "Processing order.....";
      this.retryPayment();
    },
    processOrderPayment(orderId: string) {
      this.loadingMessage = "Processing Payment...";
      this.pollInterval = setInterval(async () => {
        const updatedOrder = await this.fetchOrder(`?orderId=${orderId}`);
        if (
          updatedOrder &&
          (updatedOrder as Order).paymentStatus == "completed"
        ) {
          this.$swal.fire({
            icon: "success",
            title: "Order Paid",
            text: "Order Paid successful ",
          });
          this.isLoading = false;
          this.cashDialog = false;
          this.cardDialog = false;
          this.mpesaDialog = false;
          this.resetForms();
          this.$router.push(`/order/${orderId}`);
          clearInterval(this.pollInterval);
        }
      }, 5000);

      this.pollTimeout = setTimeout(async () => {
        console.log("--Timeout--");
        clearInterval(this.pollInterval);
        const updatedOrder = await this.fetchOrder(`?orderId=${orderId}`);
        this.order = updatedOrder as Order;
        this.paymentDone = true;
      }, 60000);
    },
    resetForms() {
      this.mpesa.phone = "";
      this.cash = {
        cash_amount: 0,
        cash_balance: 0,
        total_amount: 0,
      };
      this.gift_code = "";
      this.$store.dispatch("cart/deleteCart");
    },

    resetQrPayment() {
      this.qr.step = 1;
      this.qrDialog = false;
      this.qr.creatingOrder = false;
      this.qr.fetchingOrder = false;
      this.qrcode = "";
      this.qr.timer = 300;

      clearInterval(this.qr.interval as any);
    },
    qrTimer() {
      if (this.qr.timer > 0) {
        setTimeout(() => {
          this.qr.timer -= 1;
          this.qrTimer();
        }, 1000);
      }
    },
    async payByPaystack() {
      const url = await this.initTransaction({
        amount: this.grandTotal * 100,
        reference: this.order._id,
        email: (this.order.client as Client).email,
        subaccount: (this.order.business as Business).paystackSubaccountCode,
      });

      this.authUrl = url;
      this.paystackDialog = true;
      //this.isLoading = false;
      this.processOrderPayment(this.order._id);
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("ORDER_CHECKOUT")) {
      this.$store.registerModule("ORDER_CHECKOUT", OrderStoreModule);
    }

    if (!this.$store.hasModule("PAYSTACK_")) {
      this.$store.registerModule("PAYSTACK_", paystackStoreModule);
    }
    if (!this.$store.hasModule("CHECK_VOUCHER")) {
      this.$store.registerModule("CHECK_VOUCHER", voucherStoreModule);
    }
  },
  beforeDestroy() {
    clearTimeout(this.pollTimeout);
    clearInterval(this.pollInterval);
    this.$store.unregisterModule("ORDER_CHECKOUT");
    this.$store.unregisterModule("ORDER_PAYMENTS");
    this.$store.unregisterModule("PAYMENT_GATEWAY");
    this.$store.unregisterModule("PAYSTACK_");
    this.$store.unregisterModule("CHECK_VOUCHER");
  },
});
